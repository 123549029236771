$blue: #005182;
$pink: #e2c6be;
$pink-900: #c8a094;
$indigo: #6610f2;
$purple: #6f42c1;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$theme-colors: (
        "primary": $pink,
        "secondary": $blue,
        "danger": $red,
        "success": $green
);
$link-color: #fff;
$link-hover-color: #fcd3d3;

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 8
);

$font-family-base: "Open Sans", sans-serif;
$font-size-base: 1rem;
$h4-font-size: $font-size-base * 1.4;

@import "node_modules/bootstrap/scss/bootstrap";
@import "./app";
@import "./newsBox";
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
