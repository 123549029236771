.news-box {
  border: 1px solid $blue;
  color: $blue;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 5rem;
  text-decoration: none;
  .news-short {
    font-weight: 500;
  }
  transition: 0.5s;

  &:hover {
    background: $pink;
    border-color: $pink;
    color: white;
    .date-box {
      background: white;
      .date-box-text {
        color: $blue;
      }
    }
  }
}
.date-box {
  background: $pink;
  width: 120px;
  height: 120px;
  font-size: 1.2rem;
  margin: 0 auto;
  position: relative;
  .date-box-text {
    word-spacing: 500px;
    color: white;
    font-weight: bold;
    text-align: left;
    line-height: 1;
    position: absolute;
    top: 30px;
    left: 40px;
  }
}
