.App-header {
  background: #020609;
  transition: height 0.4s ease-in-out;
  height: 70px;

  .main-navbar {
    padding: 15px 0;
    align-content: center;

    #basic-navbar-nav {
      .active {
        color: $link-hover-color;
      }

      a {
        color: white;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    .lang-menu {
      padding-left: 1.5rem;

      @media screen and (max-width: map-get($grid-breakpoints, lg)) {
        padding-left: 0;
      }

      a {
        padding: 0.5rem 0.2rem;
        text-decoration: none;
        display: inline-block;
      }
    }

    .navbar-nav {
      background: #020609;
      padding: 2em;
      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        flex-direction: row;
        display: flex;
        flex: 1 0 0%;
        background: transparent;
        padding: 0;
      }
    }
  }

  .logo {
    height: 40px;
    transition: all .4s ease;

    img {
      display: none;
    }

    .text-logo {
      display: block;
      margin-top: 5px;
    }
  }

  &.subpage {
    height: 160px;
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      background-image: url("./backgrounds/menu-subpages.jpg");
      min-height: 160px;
    }
    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      height: 200px;
    }

    background-color: black;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;

    .logo {
      height: 130px;

      img {
        &.logo-mobile {
          @media screen and (max-width: map-get($grid-breakpoints, md)) {
            display: block;
          }
        }

        &.logo-desktop {
          @media screen and (min-width: map-get($grid-breakpoints, lg)) {
            display: block;
          }
        }
      }

      .text-logo {
        display: none;
      }
    }
  }
}

.home {
  position: relative;

  .title-image {
    height: 100vh;
    background: #020609;

    .react-parallax-content {
      height: 100%;

      img {
        position: absolute;
        top: 35%;
        left: 30%;
      }
    }
  }
}

.react-parallax-background-children {
  width: 100%;
  height: 100%;

  .parallax-bg {
    width: 100%;
    height: 100%;
  }

  .parallax-title-image {
    background-image: url('./titles/title.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #020609;
    @media screen and (max-width: map-get($grid-breakpoints, lg)) {
      background-image: url("./titles/title-mobile.jpg");
      background-size: cover;
    }
  }
}

.page-title {
  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }
}

.spaced-link {
  letter-spacing: 3px;
}

.content {
  background-image: url("./backgrounds/bg-body-top.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  border-top: 7px solid $pink;
}

body {
  background-image: url("./backgrounds/bg-body-bottom.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}

.pag {
  .page-item {

    a {
      color: $secondary;
      background: transparent;
      border: none;

      &:hover {
        background: transparent;
        color: $pink;
      }

      &:focus {
        box-shadow: none;
        background: transparent;
      }

      &:active {
        box-shadow: none;
        background: transparent;
      }
    }

    &.disabled {
      span {
        background: transparent;
        color: $secondary;
        border: none;
      }
    }

    &.active {
      a {
        background: transparent;
        color: $pink;
      }
    }

  }
}

.page {
  margin-top: 160px;
  min-height: 800px;
  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    margin-top: 200px;
  }
  a {
    color: #005182;
    &:hover {
      color: #c8a094;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $pink-900;
  text-transform: uppercase;
  letter-spacing: 7px;
}

h6 {
  letter-spacing: 4px;
}

hr {
  color: $pink-900;
  height: 2px !important;
  width: 100%;
  margin: 5rem auto !important;
}

.hr-full-blue {
  width: auto;
  color: $primary;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}

.normal-spacing {
  letter-spacing: 1px;
}

.download-icon {
  span {
    vertical-align: middle;
  }
}

.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: black;
  padding-bottom: 1em;
  margin-bottom: 2em;
  padding-left: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: black;
    text-transform: uppercase;
    font-size: 1em;
  }
}

.btn-outline-secondary {
  color: $blue;
}

.text-justify {
  text-align: justify;
}


.loader {
  visibility: hidden;
  opacity: 0;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  background-color: #020609;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s ease-out;

  .inner-loader {
    background-size: 20%;
    width: 100vw;
    height: 100vh;
    background-image: url("./titles/title-logo-center.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: map-get($grid-breakpoints, lg)) {
      background-size: 80%;
    }
  }
}

.normal-transform {
  text-transform: none !important;
}

.text-editor {
  background: white;
  margin-bottom: 1.5em;
  border: solid 1px #ccc;

  .public-DraftEditor-content {
    height: 300px;
  }
}

.text-editor-toolbar {
  a {
    color: black;

    &:hover {
      color: black;
    }
  }
}

.font-weight-medium {
  font-weight: 500;
}

.photo-delete {
  .photo-delete-icon {
    display: none;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(222, 15, 15, 0.6);
    a {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    .photo-delete-icon {
      display: block;

    }
  }
}
